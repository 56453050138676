@import url("https://fonts.googleapis.com/css2?family=Rubik&display=swap");

* {
  font-family: "Rubik", sans-serif;
}

.mynavext {
  overflow-x: hidden !important;
  position: absolute;
  background-image: url(../utils/bluetexd.jpg);
  width: 100vw;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: green;
  background-blend-mode: overlay;
}
.mynav {
  background-image: url(../utils/textgreen.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  /* background-color: rgba(0, 0, 0, 0.076); */
  background-color: rgba(8, 90, 8, 0.62);
  background-blend-mode: overlay;
}

.button-29 {
  align-items: center;
  appearance: none;
  background-image: radial-gradient(100% 100% at 100% 0, #76ff5a 0, green 100%);
  border: 0;
  border-radius: 6px;
  box-shadow: rgba(38, 66, 35, 0.4) 0 2px 4px,
    rgba(39, 66, 35, 0.3) 0 7px 13px -3px, rgba(70, 111, 58, 0.5) 0 -3px 0 inset;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;

  height: 48px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 16px;
  padding-right: 16px;

  text-align: left;
  text-decoration: none;
  transition: box-shadow 0.15s, transform 0.15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow, transform;
  font-size: 18px;
}

.button-29:focus {
  box-shadow: green 0 0 0 1.5px inset, rgba(35, 66, 39, 0.4) 0 2px 4px,
    rgba(35, 66, 38, 0.3) 0 7px 13px -3px, rgb(1, 38, 1) 0 -3px 0 inset;
}

.button-29:hover {
  box-shadow: rgba(39, 66, 35, 0.4) 0 4px 8px,
    rgba(35, 66, 37, 0.3) 0 7px 13px -3px, #205a01 0 -3px 0 inset;
  transform: translateY(-2px);
}

.button-29:active {
  box-shadow: green 0 3px 7px inset;
  transform: translateY(2px);
}
.mytyper {
  font-size: 18px !important;
}

@media (min-width: 768px) {
  .mytyper {
    font-size: 22px !important;
  }
}
@media (min-width: 1024px) {
  .mytyper {
    font-size: 25px !important;
  }
}

.fade-in {
  -webkit-animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 350px) {
  .button-29 {
    margin-top: -65px !important;
  }
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  width: 300px;
  height: 300px;
  text-align: center;
  font-size: 27px;
  /* background: #fff; */
  font-weight: BOLD;
  color: rgba(128, 128, 128, 0.656);

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide {
  width: 50%;
}

.swiper-slide:nth-child(1n) {
  width: 50%;
}
.swiper-slide:nth-child(2n) {
  width: 50%;
}

.swiper-slide:nth-child(3n) {
  width: 50%;
}

@media (max-width: 653px) {
  .swiper-slide {
    width: 100%;
  }

  .swiper-slide:nth-child(1n) {
    width: 100%;
  }
  .swiper-slide:nth-child(2n) {
    width: 100%;
  }

  .swiper-slide:nth-child(3n) {
    width: 100%;
  }
}
@media (min-width: 1200px) {
  .swiper-slide {
    width: 30%;
  }

  .swiper-slide:nth-child(1n) {
    width: 30%;
  }
  .swiper-slide:nth-child(2n) {
    width: 30%;
  }

  .swiper-slide:nth-child(3n) {
    width: 30%;
  }
}

.modal {
  padding-left: 0 !important;
}

.map-container {
  height: 100%;
  width: 100%;
}

@media (max-width: 355px) {
  .mydiv {
    margin-top: 20px !important;
  }
}

input:focus {
  border: 2px solid green !important;
}

@media (max-width: 470px) {
  .myinput {
    width: 280px !important;
  }
}

@media (min-width: 1365px) {
  .mycont {
    padding-left: 230px !important;
  }
}

.pl {
  display: block;
  width: 4.25em;
  height: 4.25em;
  /* margin-top: -170px; */
}
.pl__ring,
.pl__ball {
  animation: ring 2s ease-out infinite;
}
.pl__ball {
  animation-name: ball;
}

/* Dark theme  */
@media (prefers-color-scheme: dark) {
  :root {
    --bg: hsl(var(--hue), 10%, 10%);
    --fg: hsl(var(--hue), 10%, 90%);
  }
}

/* Animation */
@keyframes ring {
  from {
    stroke-dasharray: 0 257 0 0 1 0 0 258;
  }
  25% {
    stroke-dasharray: 0 0 0 0 257 0 258 0;
  }
  50%,
  to {
    stroke-dasharray: 0 0 0 0 0 515 0 0;
  }
}
@keyframes ball {
  from,
  50% {
    animation-timing-function: ease-in;
    stroke-dashoffset: 1;
  }
  64% {
    animation-timing-function: ease-in;
    stroke-dashoffset: -109;
  }
  78% {
    animation-timing-function: ease-in;
    stroke-dashoffset: -145;
  }
  92% {
    animation-timing-function: ease-in;
    stroke-dashoffset: -157;
  }
  57%,
  71%,
  85%,
  99%,
  to {
    animation-timing-function: ease-out;
    stroke-dashoffset: -163;
  }
}
/* 
.fade-in-tl {
  -webkit-animation: fade-in-tl 1.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-tl 1.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes fade-in-tl {
  0% {
    -webkit-transform: translateX(-50px) translateY(-50px);
    transform: translateX(-50px) translateY(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) translateY(0);
    transform: translateX(0) translateY(0);
    opacity: 1;
  }
}
@keyframes fade-in-tl {
  0% {
    -webkit-transform: translateX(-50px) translateY(-50px);
    transform: translateX(-50px) translateY(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) translateY(0);
    transform: translateX(0) translateY(0);
    opacity: 1;
  }
} */

.fade-in-tl {
  -webkit-animation: fade-in-tl 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: fade-in-tl 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
@-webkit-keyframes fade-in-tl {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes fade-in-tl {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

.modal {
  display: flex !important;
  height: 100vh;
  align-items: center;
}

@media (max-width: 400px) {
  .tobecontrolled {
    max-width: 100%;
  }
}
@media (min-width: 575px) {
  .tobecontrolled {
    max-width: 70%;
  }
}

.vibrate-1 {
  -webkit-animation: vibrate-1 0.3s linear infinite both;
  animation: vibrate-1 0.8s linear infinite both;
}

@-webkit-keyframes vibrate-1 {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  20% {
    -webkit-transform: translate(-1px, 2px);
    transform: translate(-1px, 2px);
  }
  40% {
    -webkit-transform: translate(-1px, -1px);
    transform: translate(-1px, -1px);
  }
  60% {
    -webkit-transform: translate(2px, 2px);
    transform: translate(2px, 2px);
  }
  80% {
    -webkit-transform: translate(2px, -1px);
    transform: translate(2px, -1px);
  }
  100% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}
@keyframes vibrate-1 {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  20% {
    -webkit-transform: translate(-1px, 2px);
    transform: translate(-1px, 2px);
  }
  40% {
    -webkit-transform: translate(-1px, -1px);
    transform: translate(-1px, -1px);
  }
  60% {
    -webkit-transform: translate(2px, 2px);
    transform: translate(2px, 2px);
  }
  80% {
    -webkit-transform: translate(2px, -1px);
    transform: translate(2px, -1px);
  }
  100% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

.carousel-control-prev-icon {
  background-image: none !important;
}

.carousel-control-next-icon {
  background-image: none !important;
}

.carousel-indicators {
  display: none !important;
}

.zoominheader {
  -webkit-animation: zoomin 20s ease-in infinite;
  animation: zoomin 20s ease-in infinite;
  transition: all 0.5s ease-in-out;
  overflow: hidden;
}

/* The innermost element*/
.zoomoutheader {
  -webkit-animation: zoomout 20s ease-in infinite;
  animation: zoomout 20s ease-in infinite;
  transition: all 0.5s ease-in-out;
  overflow: hidden;
}

/* Zoom in Keyframes */
@-webkit-keyframes zoomin {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.08);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes zoomin {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.08);
  }
  100% {
    transform: scale(1);
  }
} /*End of Zoom in Keyframes */

/* Zoom out Keyframes */
@-webkit-keyframes zoomout {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.67);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes zoomout {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.67);
  }
  100% {
    transform: scale(1);
  }
} /*End of Zoom out Keyframes */

.slide-in-bottom {
  -webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

.fade-in {
  -webkit-animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.animate-charcter {
  text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #46d646 0%,
    #064c5f 29%,
    #46d646 67%,
    #064c5f 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 1.5s linear infinite;
  display: inline-block;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}
