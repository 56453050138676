/* /* .App {
  text-align: center;
} */

body {
  overflow-x: hidden;
}
.mynavlink {
  font-size: 16px;
  margin-right: 20px;
  text-transform: uppercase;
  font-weight: 500;
  cursor: pointer;
}
.mynavlink2 {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 16px;
  margin-top: 20px;
  cursor: pointer;
  color: black;
}
.paystack-button {
  background-color: red;
  border-radius: 20px;
  padding: 10px;
  color: white;
}

.modNav {
  transition: all 3s ease-in;
}

.scale-in-center {
  -webkit-animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
@-webkit-keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

.scale-out-center {
  -webkit-animation: scale-out-center 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53)
    both;
  animation: scale-out-center 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

@-webkit-keyframes scale-out-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
}
@keyframes scale-out-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
}

/* CSS */
.button-89 {
  --b: 3px; /* border thickness */
  --s: 0.45em; /* size of the corner */
  --color: rgb(240, 42, 42);

  padding: calc(0.5em + var(--s)) calc(0.9em + var(--s));
  color: var(--color);
  --_p: var(--s);
  background: conic-gradient(
      from 90deg at var(--b) var(--b),
      #0000 90deg,
      var(--color) 0
    )
    var(--_p) var(--_p) / calc(100% - var(--b) - 2 * var(--_p))
    calc(100% - var(--b) - 2 * var(--_p));
  transition: 0.3s linear, color 0s, background-color 0s;
  outline: var(--b) solid #0000;
  outline-offset: 0.6em;
  font-size: 16px;

  border: 0;

  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-89:hover,
.button-89:focus-visible {
  --_p: 0px;
  outline-color: var(--color);
  outline-offset: 0.05em;
}

.button-89:active {
  background: var(--color);
  color: #fff;
}

td {
  text-align: center;
  padding: 20px;
}

@media (max-width: 510px) {
  td,
  tr {
    padding: 0px;
  }
}

@keyframes zoominoutsinglefeatured {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.1, 1.07);
  }
}

.cardcontainer {
  animation: zoominoutsinglefeatured 20s infinite;
}

.autoTransition {
  transition: width 2s, height 2s, transform 2s;
}

@media (max-width: 500px) {
  /* CSS */
  .button-89 {
    padding: calc(0.2em + var(--s)) calc(0.3em + var(--s));
  }
}

.index-module_type__E-SaG {
  font-size: 1.125rem !important;
}

@media (min-width: 640px) {
  .index-module_type__E-SaG {
    font-size: 1.25rem !important;
  }
}
@media (min-width: 768px) {
  .index-module_type__E-SaG {
    font-size: 1.875rem !important;
  }
}

.accordion-button {
  border: none;
  outline: none;
  box-shadow: none;
}

.accordion-body {
  font-weight: 700;
}

.accordion-button:focus {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}
